import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import { Flex } from "theme-ui"
import { useCookies } from "react-cookie"

import Spacing from "../ui/Spacing"
import Accordion from "../new-ui/accordion"
import { Cookie } from "../new-ui/cookies"
import useDisableBackgroundScroll from "../../hooks/useDisableBackgroundScroll"
import Button from "../new-ui/button"
import Toggle from "../new-ui/toggle/toggle"

export const FIRST_INTERACTION_EVENTS = [
  "click",
  "scroll",
  "mousemove",
  "touchstart",
]

export const MARKETING_AND_ANALYTICS_CATEGORIES = [
  "A/B Testing",
  "Analytics",
  "Attribution",
  "Email",
  "Enrichment",
  "Heatmaps & Recordings",
  "Raw Data",
  "Realtime Dashboards",
  "Referrals",
  "Surveys",
  "Video",
]

export const ADVERTISING_CATEGORIES = ["Advertising", "Tag Managers"]

export const FUNCTIONAL_CATEGORIES = [
  "CRM",
  "Customer Success",
  "Deep Linking",
  "Helpdesk",
  "Livechat",
  "Performance Monitoring",
  "Personalization",
  "SMS & Push Notifications",
  "Security & Fraud",
]

const Row = styled.div`
  ${Spacing}
  display: flex;
  flex-wrap: wrap;
  justify-content: ${(props) => props.justifyContent || "space-between"};

  &.switches {
    ${(props) => props.theme.breakpointsLegacy.tablet} {
      width: 65%;
    }
  }

  &.but-container {
    padding-top: 20px;
    padding-bottom: 20px;
    flex-direction: row;

    .but-acc,
    .but-save {
      height: 40px;
      flex: 1;
      font-size: 14px;
      text-align: center;
      font-weight: bold;
      padding: 8px;
    }

    .but-save {
      border: ${(props) => props.theme.borders.muted};
      margin-right: 4px;
    }

    .but-acc {
      margin-left: 4px;
    }

    ${(props) => props.theme.breakpointsLegacy.tablet} {
      .but-acc,
      .but-save {
        height: 40px;
        width: 140px;
      }

      .but-acc {
        margin-bottom: 0;
      }
    }
  }
`

const Text = styled.div`
  margin-bottom: 20px;

  &.show-details {
    text-decoration: underline;
    cursor: pointer;
  }
`

const Modal = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    justify-content: flex-end;
    align-items: flex-end;
    padding: 16px;
  }
`

const ModalContent = styled.div`
  padding: 20px;
  width: 90%;
  max-height: 90vh;
  overflow-y: scroll;
  background-color: white;
  max-width: 600px;

  ${(props) => props.theme.breakpointsLegacy.tablet} {
    padding: 40px;
    width: 60%;
    filter: drop-shadow(0px 4px 50px rgba(0, 0, 0, 0.08));
  }

  ${(props) => props.theme.breakpointsLegacy.desktop} {
    padding: 50px;
  }
`

const Content = styled.div`
  max-width: 100%;
  font-size: 13px;

  h2 {
    font-size: 19px;
    font-weight: normal;
  }
`

const SwitchContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const SwitchLabel = styled.div`
  margin-bottom: 5px;
  font-size: 12px;
`

const CookieListContainer = styled(Flex)`
  flex-direction: column;
`
const CookieList = styled(Flex)`
  flex-direction: column;
  margin: 16px 0;
  max-height: 300px;
  overflow: scroll;
`

const CookieConsentBanner = () => {
  const [showDetails, setShowDetails] = useState(false)
  const [cookieData, setCookieData] = useState()
  const [, setScrollable] = useDisableBackgroundScroll()
  const [loading, setLoading] = useState(true)
  const [showDialog, setShowDialog] = useState(true)
  const [options, setOptions] = useState({
    functional: true,
    analytics: true,
    advertising: true,
  })
  const [cookies] = useCookies(["OptanonConsent", "OptanonAlertBoxClosed"])
  const [userInteracted, setUserInteracted] = useState(false)
  let reShowDialog = false

  useEffect(() => {
    let intervalVar
    // OneTrust comes from the script in html.js
    // eslint-disable-next-line no-undef
    if (loading && userInteracted) {
      intervalVar = setInterval(() => {
        if (window.OneTrust) {
          // eslint-disable-next-line no-undef
          const data = window.OneTrust.GetDomainData()

          setCookieData(data)
          setLoading(false)
        }
      }, [2000])
    }

    return () => clearInterval(intervalVar)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  const firstInteraction = React.useCallback(() => {
    setUserInteracted(true)
  }, [])

  useEffect(() => {
    FIRST_INTERACTION_EVENTS.forEach(function (e) {
      window.addEventListener(e, firstInteraction, {
        once: true,
      })
    })

    return () => {
      FIRST_INTERACTION_EVENTS.forEach(function (e) {
        window.removeEventListener(e, firstInteraction)
      })
    }
  }, [firstInteraction])

  if (cookies.OptanonConsent && window.gtag) {
    const consentSettings = {
      analytics_storage: "denied",
      ad_storage: "denied",
      ad_user_data: "denied",
      ad_personalization: "denied",
      functionality_storage: "denied",
      personalization_storage: "denied",
      security_storage: "denied",
      wait_for_update: 500,
    }

    if (cookies.OptanonConsent?.includes("C0002:1")) {
      consentSettings.analytics_storage = "granted"
    }

    if (cookies.OptanonConsent?.includes("C0003:1")) {
      consentSettings.ad_storage = "granted"
      consentSettings.ad_user_data = "granted"
      consentSettings.ad_personalization = "granted"
    }

    if (cookies.OptanonConsent?.includes("C0004:1")) {
      consentSettings.functionality_storage = "granted"
    } else {
      const currentTime = new Date().getTime()
      const consentTime = cookies.OptanonAlertBoxClosed
        ? new Date(cookies.OptanonAlertBoxClosed).getTime()
        : currentTime

      // 1 month elapsed
      if (currentTime - consentTime > 30 * 24 * 60 * 60 * 1000) {
        reShowDialog = true
      }
    }

    if (
      cookies.OptanonConsent?.includes("C0002:1") &&
      cookies.OptanonConsent?.includes("C0003:1") &&
      cookies.OptanonConsent?.includes("C0004:1")
    ) {
      consentSettings.personalization_storage = "granted"
      consentSettings.security_storage = "granted"
    }

    window.gtag("consent", "update", consentSettings)
  }

  return (process.env.NODE_ENV === "staging" ||
    process.env.NODE_ENV === "production") &&
    window.OneTrust &&
    typeof window.OneTrust.IsAlertBoxClosedAndValid !== "undefined" &&
    (!window.OneTrust.IsAlertBoxClosedAndValid() || reShowDialog) &&
    userInteracted &&
    showDialog ? (
    <Modal>
      <ModalContent>
        <Content>
          <h2>We take care of your data</h2>
          <Text>
            At Tekla, we take data security and confidentiality seriously. This
            means we ensure appropriate protection and responsible processing of
            all personal information that we receive.
          </Text>
          <Text>
            We assure full confidentiality when handling and storing your
            personal data submitted on this site. Please feel free to adjust
            your cookie preferences below.
          </Text>
          <Row>
            <Text
              className="show-details"
              onClick={() => setShowDetails((showing) => !showing)}
            >
              {showDetails ? "Hide details" : "Show details"}
            </Text>
          </Row>
          {showDetails && (
            <CookieListContainer>
              <Accordion
                items={cookieData?.Groups.reduce((acc, current) => {
                  if (current.Cookies?.length === 0) return acc
                  const item = {
                    id: current.GroupName,
                    name: current.GroupName,
                    contentRender: function createCookieList() {
                      return (
                        <CookieList>
                          <Text>{current.GroupDescription}</Text>
                          {current?.Cookies.map((cookie, index) => (
                            <Cookie
                              key={`${cookie.Name}-index-${index}`}
                              name={cookie.Name}
                              description={cookie.description}
                              duration={cookie.Length}
                            />
                          ))}
                        </CookieList>
                      )
                    },
                  }
                  acc.push(item)

                  return acc
                }, [])}
              />
            </CookieListContainer>
          )}
          <Row className="but-container" justifyContent="flex-start">
            <Button
              className="but-save"
              variant="transparent"
              outline
              secondary
              onClick={() => {
                setScrollable(true)
                setShowDialog(false)

                if (window.OneTrust) {
                  if (
                    !options.functional &&
                    !options.analytics &&
                    !options.advertising
                  ) {
                    window.gtag("consent", "update", {
                      ad_storage: "denied",
                      analytics_storage: "denied",
                      functionality_storage: "denied",
                      ad_user_data: "denied",
                      ad_personalization: "denied",
                      personalization_storage: "denied",
                      security_storage: "denied",
                    })

                    window.OneTrust.RejectAll()
                  } else {
                    if (options.functional) {
                      window.OneTrust.UpdateConsent("Category", "C0002:1")

                      window.gtag("consent", "update", {
                        analytics_storage: "granted",
                        wait_for_update: 500,
                      })
                    }

                    if (options.analytics) {
                      window.gtag("consent", "update", {
                        ad_storage: "granted",
                        ad_user_data: "granted",
                        ad_personalization: "granted",
                        wait_for_update: 500,
                      })
                      window.OneTrust.UpdateConsent("Category", "C0003:1")
                    }

                    if (options.advertising) {
                      window.gtag("consent", "update", {
                        functionality_storage: "granted",
                        wait_for_update: 500,
                      })
                      window.OneTrust.UpdateConsent("Category", "C0004:1")
                    }

                    if (
                      options.functional &&
                      options.analytics &&
                      options.advertising
                    ) {
                      window.gtag("consent", "update", {
                        personalization_storage: "granted",
                        security_storage: "granted",
                        wait_for_update: 500,
                      })
                    }
                  }

                  window.OneTrust.SetAlertBoxClosed()
                }
              }}
            >
              Save preferences
            </Button>
            <Button
              className="but-acc"
              variant="primary"
              margin="0 0 0 20px"
              onClick={() => {
                setScrollable(true)
                setShowDialog(false)

                if (window.OneTrust) {
                  window.gtag("consent", "update", {
                    ad_storage: "granted",
                    analytics_storage: "granted",
                    functionality_storage: "granted",
                    ad_user_data: "granted",
                    ad_personalization: "granted",
                    personalization_storage: "granted",
                    security_storage: "granted",
                  })
                  window.OneTrust.AllowAll()
                  window.OneTrust.SetAlertBoxClosed()
                }
              }}
            >
              Accept all
            </Button>
          </Row>

          <Row justifyContent="space-between" className="switches">
            <SwitchContainer>
              <SwitchLabel>Necessary</SwitchLabel>
              <Toggle alwaysOn={true} isOn={true} />
            </SwitchContainer>
            <SwitchContainer>
              <SwitchLabel>Statistical</SwitchLabel>
              <Toggle
                isOn={!!options.analytics}
                onClick={() => {
                  setOptions({ ...options, analytics: !options.analytics })
                }}
              />
            </SwitchContainer>
            <SwitchContainer>
              <SwitchLabel>Marketing</SwitchLabel>
              <Toggle
                isOn={!!options.advertising}
                onClick={() => {
                  setOptions({ ...options, advertising: !options.advertising })
                }}
              />
            </SwitchContainer>
            <SwitchContainer>
              <SwitchLabel>Functional</SwitchLabel>
              <Toggle
                isOn={!!options.functional}
                onClick={() => {
                  setOptions({ ...options, functional: !options.functional })
                }}
              />
            </SwitchContainer>
          </Row>
        </Content>
      </ModalContent>
    </Modal>
  ) : null
}

export default CookieConsentBanner
